@tailwind base;
@tailwind components;
@tailwind utilities; 
 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica;
}

html {
  scroll-behavior: smooth;
}